@font-face {
font-family: '__suisseIntl_9adcc3';
src: url(/_next/static/media/c42cb4950679bdba-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__suisseIntl_9adcc3';
src: url(/_next/static/media/472e33f1978dd82f-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__suisseIntl_9adcc3';
src: url(/_next/static/media/3f54f739b88223ba-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__suisseIntl_9adcc3';
src: url(/_next/static/media/c5d1cfe683dde341-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: italic;
}

@font-face {
font-family: '__suisseIntl_9adcc3';
src: url(/_next/static/media/12af057b62dc3e6b-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__suisseIntl_9adcc3';
src: url(/_next/static/media/6024306fbad6898f-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: italic;
}@font-face {font-family: '__suisseIntl_Fallback_9adcc3';src: local("Arial");ascent-override: 95.68%;descent-override: 30.18%;line-gap-override: 0.00%;size-adjust: 103.05%
}.__className_9adcc3 {font-family: '__suisseIntl_9adcc3', '__suisseIntl_Fallback_9adcc3'
}.__variable_9adcc3 {--font-suisse: '__suisseIntl_9adcc3', '__suisseIntl_Fallback_9adcc3'
}

@font-face {
font-family: '__suisseIntlMono_9ce464';
src: url(/_next/static/media/4c017ab9f28baa7b-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__suisseIntlMono_9ce464';
src: url(/_next/static/media/c3b00c5a617e1ece-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__suisseIntlMono_Fallback_9ce464';src: local("Arial");ascent-override: 69.65%;descent-override: 21.97%;line-gap-override: 0.00%;size-adjust: 141.57%
}.__className_9ce464 {font-family: '__suisseIntlMono_9ce464', '__suisseIntlMono_Fallback_9ce464'
}.__variable_9ce464 {--font-suisse-mono: '__suisseIntlMono_9ce464', '__suisseIntlMono_Fallback_9ce464'
}

